import React from "react"
import { graphql, withPrefix } from "gatsby"
import qs from 'qs';
import PropTypes from "prop-types"
import globalContent from "../../content/settings/global.yml"
import ScriptLoader from "react-script-loader-hoc"
import Layout from "../components/layout"
import SkipLinks from "../components/SkipLinks/SkipLinks"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage/HeroImage"
import ImageWithTextBox from "../components/ImageWithTextBox/ImageWithTextBox"
import BlockWithText from "../components/BlockWithText/BlockWithText"
import ListWithImage from "../components/ListWithImage/ListWithImage"
import FooterCta from "../components/FooterCta/FooterCta"
import Feefo from "../components/Feefo/Feefo"
import ThankYouMessage from "../components/ThankYouMessage/ThankYouMessage"
import Helmet from "react-helmet"

class IePPCPage extends React.Component {
  state = {
    thankYouModalIsOpen: false,
  }

  toggleThankYouMessage = () => {
    this.setState({
      thankYouModalIsOpen: !this.state.thankYouModalIsOpen,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      desktopFormID,
      mobileFormID,
    } = this.props.data.markdownRemark.frontmatter.heroSection
    const {
      footerFormID,
    } = this.props.data.markdownRemark.frontmatter.footerForm
    const { scriptsLoadedSuccessfully } = this.props
    var url = window.location.href
    if (prevProps.scriptsLoadedSuccessfully !== scriptsLoadedSuccessfully) {
      if (
        url.indexOf(`?form=mktoForm_${mobileFormID}&marketo=${mobileFormID}`) >
          -1 ||
        url.indexOf(
          `?form=mktoForm_${desktopFormID}&marketo=${desktopFormID}`
        ) > -1 ||
        url.indexOf(`?form=mktoForm_${footerFormID}&marketo=${footerFormID}`) >
          -1
      ) {
        this.toggleThankYouMessage()
      }
    }
  }

  render() {
    const { data } = this.props
    const pageData = data.markdownRemark.frontmatter
    const { thankYouModalIsOpen } = this.state
    const scriptsLoadedSuccessfully = this.props.scriptsLoadedSuccessfully
    const queryString = qs.parse(typeof window !== "undefined" ? window.location.search : null, { ignoreQueryPrefix: true })

    if (typeof window !== 'undefined') {
      const aTags = document.querySelectorAll('a');

      aTags.forEach(e => {
        const hasNumber = e.href.includes('tel');
        const hasRulerClassName = e.classList.contains('rulertel')
        if (hasNumber && !hasRulerClassName) {
          e.classList.add('rulertel');
        }
      });
    }

    return (
      <Layout>
        <Helmet>
          <script src={withPrefix("easy-cookie.js")} type="text/javascript" />
          <script src={withPrefix("cookie.js")} type="text/javascript" />
          <meta name="robots" content="noindex, nofollow"></meta>
        </Helmet>
        <SEO title={pageData.title} twitterHandle={"@peninsula_ie"} />
        <ThankYouMessage
          thankYouModalIsOpen={thankYouModalIsOpen}
          toggleThankYouMessage={this.toggleThankYouMessage}
          formTitle={pageData.thankYouModal.thankYouModalTitle}
          formBody={pageData.thankYouModal.thankYouModalBody}
        />
        <SkipLinks />
        <Header pageData={pageData} />
        <HeroImage
          pageData={pageData}
          globalContent={globalContent}
          scriptsLoadedSuccessfully={scriptsLoadedSuccessfully}
          toggleModal={this.toggleModal}
          h1={queryString.h1}
          h2={queryString.h2}
        />
        <ImageWithTextBox pageData={pageData} />
        <BlockWithText pageData={pageData} />
        <ListWithImage pageData={pageData} />
        <Feefo globalContent={globalContent} pageData={pageData} />
        <FooterCta
          scriptsLoadedSuccessfully={scriptsLoadedSuccessfully}
          pageData={pageData}
        />
        <Footer pageData={pageData} />
      </Layout>
    )
  }
}

IePPCPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ScriptLoader(
  "//app-lon04.marketo.com/js/forms2/js/forms2.min.js"
)(IePPCPage)

export const pageQuery = graphql`
  query IEPPCPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        headerSettings {
          logoLink
          headerPhoneNumber
          headerSmallText
          hideHeaderPhoneNumber
        }
        heroSection {
          heroImage {
            id
            childImageSharp {
              fluid(quality: 100) {
                src
                srcWebp
              }
            }
          }
          heroTitle
          heroSubTitle
          heroFormTitle
          heroFormSubTitle
          heroButtonText
          desktopFormID
          mobileFormID
          hideTrustPilotWidget
        }
        imageWithText {
          imageTextImage {
            childImageSharp {
              fluid(quality: 100) {
                src
                srcWebp
              }
            }
          }
          imageTextTitle
          imageTextBody
          imageTextTopAdjustment
        }
        blueTextBox {
          blueTextBoxTitle
          blueTextBoxBody
          blueTextBoxLeftTitle
          blueTextBoxLeftBody
          blueTextBoxRightTitle
          blueTextBoxRightBody
        }
        listWithImage {
          listImage {
            childImageSharp {
              fluid(quality: 100) {
                src
                srcWebp
              }
            }
          }
          listWithImageTitle
          listWithImageBody
          listWithImageOneTitle
          listWithImageOneBody
          listWithImageTwoTitle
          listWithImageTwoBody
          listWithImageThreeTitle
          listWithImageThreeBody
        }
        feefoWidget {
          feefoLink
        }
        footerForm {
          footerFormTitle
          footerFormBody
          footerFormID
          footerCopy
          showGILogo
        }
        thankYouModal {
          thankYouModalBody
          thankYouModalTitle
        }
        cBanner {
          cBannerTitle
          cBody
          cMoreInfoLinkText
          cMoreInfoLinkAnchor
          cLinkText
          cLinkAnchor
          cButtonText
        }
      }
    }
  }
`
